/* ===================================================================== */
/* ОБНУЛЕНИЕ */

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Убираем обводки линий некоторых браузеров */
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}

/* Делаем эти теги блочными */
nav,
footer,
header,
aside {
  display: block;
}

/* Уравнивают параметры шрифтов и элементов для разных браузеров */
html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

/* Просим наследовать наш подключенный шрифт */
input,
button,
textarea {
  font-family: inherit;
}

/* Убирают некоторые особенности в разных браузерах */
input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}

/* Обнуляем все заголовки h */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}

/* ===================================================================== */

body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased; /* Сглаживает шрифты */
  font-size: 14px;
  color: #000000;
  background: url("../src/img/background.svg") center center / cover repeat;
}

/* Container */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* ===================================================================== */

/* HEADER */

.header {
  display: flex;
  flex-direction: column; /* каждый новый элемент intro будет начинаться с новой строки */
  justify-content: center;
  padding-top: 77px;
  text-align: left;
}

.header-inner {
  display: flex;
  align-items: center;
  height: 85px; /* Высота логотипа */
  margin: 0 auto;
}

.header-logo {
  margin-right: 20px; /* Расстояние между логотипом и текстом */
}

.header-title {
  font-size: 64px; /* Меньший размер для h1 */
  color: #595b79;
  font-weight: 800;
}

.header-subtitle {
  color: #747593;
  font-size: 28px;
}

/* ===================================================================== */

/* MAIN */

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 70px;
  text-align: left;
}

.main-inner {
  display: flex;
  align-items: flex-start; /* Это свойство поднимет элементы к верху контейнера */
  margin: 0 auto;
  padding-bottom: 77px;
}

.main-logo {
  margin-right: 50px;
}

.main-title {
  font-size: 32px;
  color: #747593;
  line-height: 39px;
}

.main-subtitle {
  color: #afb0bf;
  font-size: 21px;
  line-height: 26px;
}

.main-info {
  color: #747593;
  font-size: 20px;
  margin: 30px 0px 14px 0px;
}

.main-info-text {
  font-weight: 500;
  color: rgba(116, 117, 147, 0.5);
  line-height: 17px;
}

.main-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  border-radius: 8px;
  background: #9cc977;
  box-shadow: 0px 6px 0px 0px rgba(83, 104, 65, 0.1);
  border: none;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  font-size: 20px;
  color: rgba(83, 104, 65, 0.58);
  font-weight: 700;
  margin-top: 40px;
}

.button-text {
  margin-right: 10px; /* Отступ справа от текста */
}

.button-icon {
  display: flex;
}

.main-button:active {
  box-shadow: 0px 0px 0px 0px rgba(83, 104, 65, 0.1);
  transform: translateY(4px);
}

@media screen and (max-width: 768px) {
  .header {
    padding-top: 50px;
  }

  .header-logo {
    margin: 0px; /* Расстояние между логотипом и текстом */
  }

  .header-text {
    padding: 20px 0px;
  }

  .main {
    margin-top: 30px;
  }

  .main-inner {
    padding-bottom: 50px;
  }

  .header-inner,
  .main-inner {
    flex-direction: column; /* Переход к столбцовой компоновке на мобильных устройствах */
  }

  .main-inner {
    align-items: center; /* Центрирование содержимого по горизонтали */
    justify-content: center; /* Центрирование содержимого по горизонтали */
  }

  .main-logo {
    max-width: 80%; /* Уменьшение ширины логотипа */
    margin: 40px 0px;
  }
}

@media screen and (max-width: 425px) {
  .header-title {
    font-size: 40px; /* Меньший размер для h1 */
  }

  .header-subtitle {
    color: #747593;
    font-size: 22px;
  }

  .main-button {
    padding: 14px 22px;
    border-radius: 8px;
    font-size: 14px;
  }
}

@media screen and (max-width: 375px) {
.header {
  padding: 20px 0px 0px 0px;
}

  .header-title {
    font-size: 33px; /* Меньший размер для h1 */
  }

  .header-subtitle {
    color: #747593;
    font-size: 22px;
  }

  .main-button {
    padding: 14px 22px;
    border-radius: 8px;
    font-size: 14px;
    margin-top: 20px;
  }

  .main-inner {
    padding: 0px 0px 20px 0px;
  }

  .main-info {
    margin: 20px 0px;
  }

  .main-logo {
    max-width: 100%; /* Уменьшение ширины логотипа */
    margin: 20px 0px;
  }
}

@media screen and (max-width: 320px) {
  .header {
    padding: 20px 0px 0px 0px;
  }
  
    .header-title {
      font-size: 30px; /* Меньший размер для h1 */
    }
  
    .header-subtitle {
      color: #747593;
      font-size: 20px;
    }
  
    .main-button {
      padding: 14px 22px;
      border-radius: 8px;
      font-size: 14px;
      margin-top: 20px;
    }
  
    .main-inner {
      padding: 0px 0px 20px 0px;
    }
  
    .main-info {
      margin: 10px 0px;
    }
  
    .main-logo {
      max-width: 100%; /* Уменьшение ширины логотипа */
      margin: 20px 0px;
    }
  }